import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { loadings, requestSign, getOneDayTime } from '@/utils/http.js'
import { syncTime ,syncTimePost} from '@/api/useTime.js'
import { nowDateTime } from '@/utils/date.js'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: '/',
    redirect: '/login'
  },
  // {
  //   path: '/changeLevel',
  //   name: '/changeLevel',
  //   component: () => import('@/views/changeLevel/index.vue')
  // },
  {
    path: '/code',
    name: '/code',
    component: () => import('@/views/code/index.vue')
  },
  {
    path: '/code2',
    name: '/code2',
    component: () => import('@/views/code/index2.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      pageMap: 1000,
    },
    component: () => import('@/views/login/index.vue')
  },
  { // 招商演示页面
    path: '/demonstrate',
    name: 'demonstrate',
    component: () => import('@/views/demo/index.vue')
  },
  { // 招商演示页面详情
    path: '/demoTopic',
    name: 'demoTopic',
    component: () => import('@/views/demo/components/topicInfo.vue')
  },

  { // 用户注册
    path: '/register',
    name: 'register',
    component: () => import('@/views/register/index.vue'),
    meta: {
      title: '天天进步智能学伴'
    }

  },
  { // 激活页面
    path: '/activate',
    name: 'activate',
    component: () => import('@/views/activate/index.vue'),
    meta: {
      title: '天天进步智能学伴'
    }

  },
  { //  激活-基本信息
    path: '/activate/info',
    name: 'activate/info',
    component: () => import('@/views/activate/info.vue'),
    meta: {
      title: '天天进步智能学伴'
    }
  },
  // { //  激活-隐私政策
  //   path: '/activate/policy',
  //   name: 'activate/policy',
  //   component: () => import('@/views/activate/components/policy.vue')
  // },
  // { //  激活-用户协议
  //   path: '/activate/protocol',
  //   name: 'activate/protocol',
  //   component: () => import('@/views/activate/components/protocol.vue')
  // },
  { //  激活-成功
    path: '/activate/success',
    name: 'activate/success',
    component: () => import('@/views/activate/components/success.vue'),
    meta: {
      title: '天天进步智能学伴'
    }
  },
  {
    path: '/downLoad',
    name: 'downLoad',
    component: () => import('@/views/downLoad/index.vue'),
    meta: {
      title: '天天进步智能学伴'
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home.vue'),
    redirect: '/index',

    children: [
      {
        path: '/free',
        name: 'free',
        component: () => import('@/views/free/wrap.vue')
      },
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/index.vue')
      },
      // // 错题本
      // {
      //   path: '/errorbasis',
      //   name: 'errorbasis',
      //   component: () => import('@/views/errorbasis/index.vue')
      // },
      // 错题本1
      {
        path: '/errorbasis',
        name: 'errorbasis',
        meta: {
          keep: true,
          pageMap: 1013,
        },
        component: () => import('@/views/errorbasis/indexCopy.vue')
      },
      // 题目详情
      {
        path: '/topic/infoC',
        name: 'topic_infoC',
        component: () => import('@/views/errorbasis/components/topicInfoCopy.vue')
      },
      //优质课程
      {
        path: '/course',
        name: 'course',
        meta: {
          pageMap: 1014,
        },
        component: () => import('@/views/course/index.vue')
      },
      // 善利名师讲义
      {
        path: '/slHandouts',
        name: 'slHandouts',
        component: () => import('@/views/slHandouts/index.vue')
      },

      //分析报告_wrap
      {
        path: '/analyze_report_wrap',
        name: 'analyze_report_wrap',
        component: () => import('@/views/analyzeReport/wrap.vue')
      },

      //个人中心
      {
        path: '/personalCenter',
        name: 'personalCenter',
        component: () => import('@/views/personalCenter/index.vue'),
        // component: () => import('@/views/personalCenter/indexNew.vue'),

        redirect: '/info',
        children: [
          //个人信息
          {
            path: '/info',
            name: 'info',
            component: () => import('@/views/personalCenter/components/info.vue')
          },

          //学习信息
          {
            path: '/studyInfo',
            name: 'studyInfo',
            component: () => import('@/views/personalCenter/components/studyInfo.vue')
          },
          //帮助
          {
            path: '/help',
            name: 'help',
            component: () => import('@/views/personalCenter/components/help.vue')
          },
          //重置密码
          {
            path: '/resetPwd',
            name: 'resetPwd',
            component: () => import('@/views/personalCenter/components/resetPwd.vue')
          },
          //联系我们
          {
            path: '/concat',
            name: 'concat',
            component: () => import('@/views/personalCenter/components/concat.vue')
          },
          //科目班级
          {
            path: '/subjectClass',
            name: 'subjectClass',
            component: () => import('@/views/personalCenter/components/subjectClass.vue')
          },
          //我的反馈
          {
            path: '/feedback',
            name: 'feedback',
            component: () => import('@/views/personalCenter/components/feedback.vue')
          },
          //我删除的试卷
          {
            path: '/delPaper',
            name: 'delPaper',
            component: () => import('@/views/personalCenter/components/delPaper.vue')
          },
          // 用户协议
          {
            path: '/protocol',
            name: 'protocol',
            component: () => import('@/views/personalCenter/components/protocol.vue')
          },
          // 会员信息
          {
            path: '/vipInfo',
            name: 'vipInfo',
            component: () => import('@/views/vip/vipInfo/index.vue')
          },
        ]
      },
      //公益讲座
      {
        path: '/lecture_free',
        name: 'lecture_free',
        component: () => import('@/views/lecture_free/index.vue')
      },
      // 购买会员
      {
        path: '/buyVip',
        name: 'buyVip',
        component: () => import('@/views/vip/buyVip/index.vue')
      },
      // 会员分享
      {
        path: '/vipShare',
        name: 'vipShare',
        component: () => import('@/views/vip/share/index.vue')
      },
    ]
  },
  // 组卷测试
  {
    path: '/group_test',
    name: 'group_test',
    component: () => import('@/views/groupTest/index.vue')
  },
  // 题目信息（搜索题目页面）
  {
    path: '/showTopic',
    name: 'showTopic',
    component: () => import('@/views/showTopic/index.vue')
  },

  // 考点训练
  {
    path: '/testing_test',
    name: 'testing_test',
    meta: {
      pageMap: 1004
    },
    component: () => import('@/views/testing/index.vue')
  },
  // 章节训练
  {
    path: '/chapter',
    name: 'chapter',
    meta: {
      pageMap: 1003
    },
    component: () => import('@/views/chapter/index.vue'),
  },
  // 知识点
  {
    path: '/knowledge',
    name: 'knowledge',
    component: () => import('@/views/knowledge/index.vue'),

  },
  // 精选试卷
  {
    path: '/choiceness',
    name: 'choiceness',
    meta: {
      pageMap: 1002
    },
    component: () => import('@/views/choiceness/index.vue')
  },
  // 我的试卷
  {
    path: '/myPaper',
    name: 'myPaper',
    meta: {
      keep: false,
      pageMap: 1008
    },
    component: () => import('@/views/myPaper/index.vue')
  },
  // 备考进度
  {
    path: '/notPress',
    name: 'notPress',
    meta: {
      keep: false,
      pageMap: 1016
    },
    component: () => import('@/views/notPress/index.vue')
  },
  // 弱点训练
  {
    path: '/weakness',
    name: 'weakness',
    meta: {
      pageMap: 1015
    },
    component: () => import('@/views/weakness/index.vue')
  },
  // // 错题本
  // {
  //   path: '/errorbasis',
  //   name: 'errorbasis',
  //   component: () => import('@/views/errorbasis/index.vue')
  // },
  // 整卷测试
  {
    path: '/wholepaper',
    name: 'wholepaper',
    meta: {
      pageMap: 1001,
    },
    component: () => import('@/views/wholepaper/index.vue')
  },
  // 整卷测试-试卷列表
  {
    path: '/wholepaper_list',
    name: 'wholepaper_list',
    component: () => import('@/views/wholepaper/components/list.vue')
  },

  //专题讲解
  {
    path: '/lecture',
    name: 'lecture',
    component: () => import('@/views/lecture/index.vue')
  },
  //知识点讲解
  {
    path: '/knowledgeExplain',
    name: 'knowledgeExplain',
    component: () => import('@/views/knowledgeExplain/index.vue')
  },
  // 自由选题
  {
    path: '/refrence',
    name: 'refrence',
    meta: {
      pageMap: 1007
    },
    component: () => import('@/views/refrence/index.vue')
  },
  // 做题目详情
  {
    path: '/doPaper',
    name: 'doPaper',
    component: () => import('@/views/doPaper/index.vue')
  },
  // 做题的答题卡页面
  {
    path: '/dopaper_card',
    name: 'dopaper_card',
    component: () => import('@/views/dopaper_card/index.vue')
  },
  // 自评页面
  {
    path: '/self_correct',
    name: 'self_correct',
    component: () => import('@/views/paper_components/correct/index.vue')
  },
  // 答案解析
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import('@/views/paper_components/analysis/index.vue')
  },
  // 自评完的答题卡页面
  {
    path: '/over_card',
    name: 'over_card',
    component: () => import('@/views/paper_components/over_card/index.vue')
  },
  // // 善利名师讲义
  // {
  //   path: '/slHandouts',
  //   name: 'slHandouts',
  //   component: () => import('@/views/slHandouts/index.vue')
  // },
  // 公益讲义
  {
    path: '/publicHandouts',
    name: 'publicHandouts',
    component: () => import('@/views/publicHandout/index.vue')
  },
  // 知识点讲解
  {
    path: '/knowledgeVideo',
    name: 'knowledgeVideo',
    component: () => import('@/views/knowledgeVideo/index.vue')
  },
  //知识点曲线
  {
    path: '/knowledgepoint',
    name: 'knowledgepoint',
    component: () => import('@/views/knowledgePoint/index.vue')
  },
  // 学情分析
  {
    path: '/study_analysis',
    name: 'study_analysis',
    component: () => import('@/views/studentAnalysis/index.vue')
  },
  // 答疑
  {
    path: '/discuss',
    name: 'discuss',
    component: () => import('@/views/discuss/index.vue')
  },
  // 答疑详情
  {
    path: '/discuss_info',
    name: 'discuss_info',
    component: () => import('@/views/discuss/components/info.vue')
  },
  // 题目详情
  {
    path: '/topic/info',
    name: 'topic_info',
    component: () => import('@/views/errorbasis/components/topicInfo.vue')
  },
  //分析报告
  {
    path: '/analyze_report',
    name: 'analyze_report',
    component: () => import('@/views/analyzeReport/index.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test/index.vue')
  },
  // 播放视频
  {
    path: '/video',
    name: 'video',
    component: () => import('@/views/components/videoPage/index.vue')
  },
  //趣味训练
  {
    path: '/interesting',
    name: 'interesting',
    component: () => import('@/views/interesting/index.vue')
  },
  // 趣味训练答题
  {
    path: '/Answer',
    name: 'Answer',
    component: () => import('@/views/interesting/components/Answer.vue')
  },
  // 答案详情页
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/views/interesting/components/detail.vue')
  },
  //美文记忆
  {
    path: '/goodArticle',
    name: 'goodArticle',
    meta: {
      pageMap: 1010,
    },
    component: () => import('@/views/goodArticle/index.vue')
  },
  //单词记忆
  {
    path: '/wordMemory',
    name: 'wordMemory',
    component: () => import('@/views/wordMemory/index.vue')
  },
  //单词本详情
  {
    path: '/wordDetail',
    name: 'wordDetail',
    component: () => import('@/views/wordMemory/components/detail.vue')
  },
  //物理记忆
  {
    path: '/physicalMemory',
    name: 'physicalMemory',
    component: () => import('@/views/physicalMemory/index.vue')
  },
  //学科素养
  {
    path: '/capability2',
    name: 'capability2',
    meta: {
      pageMap: 1005
    },
    component: () => import('@/views/capability2/index.vue')
  },
  //关键能力
  {
    path: '/capability1',
    name: 'capability1',
    meta: {
      pageMap: 1006
    },
    component: () => import('@/views/capability1/index.vue')
  },
]
const router = new VueRouter({
  routes
})


const routerPush = router.push;
router.push = path => {
  // 判断下当前路由是否就是要跳转的路由
  if (router.currentRoute.fullPath.includes(path)) return;
  return routerPush.call(router, path);
}
// 判断是否登录
router.beforeEach((to, from, next) => {
  //新用户注册判断是否过了会员期限
  if (to.path) {
    getOneDayTime()
  }
  NProgress.start()
  let query = to.query
  let userInfo = null
  if (query.token) {
    userInfo = {
      token: query.token,
      edu_grade_id: query.edu_grade_id
    }
    window.localStorage.setItem("userInfo", JSON.stringify(userInfo))
  }

  requestSign.setNumber(0)
  if (loadings) {
    loadings.close()
  }
  if (!userInfo) {
    userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  }
  if (to.path == '/login' || !userInfo) {
    window.sessionStorage.clear()
    next()
  }
  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '天天进步智能学伴'
  }


  //统计时间
  let nowTime = nowDateTime()
  let lastTime = ''
  let startTime = ''
  if (from.path !== '/index' && Object.keys(window.localStorage).includes(`${from.path}`) && from.meta.pageMap) {
  
    lastTime = nowTime
    let data = {
      uid: userInfo.user_id,
      start_time: window.localStorage.getItem(`${from.path}`),
      end_time: lastTime,
      platform: 2,
      client: 'web',
      page: from.meta.pageMap
    }
    window.localStorage.setItem('TimeData', JSON.stringify(data))
    setTimeout(async () => {
      // await syncTime(data);
      // await syncTimePost(data)
    }, 1000)


  } else {
   
    startTime = window.localStorage.setItem(`${to.path}`, nowTime)
  }
  next()

})



router.afterEach(() => {
  // 完成进度条
  NProgress.done()
})

export default router
